import Mousetrap from 'mousetrap';

// zestaw skrotow klawiaturowych

$(document).ready(function () {
    /* lista ticketow */
    Mousetrap.bind('right', function () {
        next_page();
    }); // kolejna strona
    Mousetrap.bind('left', function () {
        previous_page();
    }); // poprzednia strona

    /* global */
    Mousetrap.bind('c', function () {
        scopeEmptyMessage('create_ticket_page()');
    }); // utworz ticket
    Mousetrap.bind('s', function () {
        scopeEmptyMessage('search_page()');
    }); // search
    Mousetrap.bind('h', function () {
        scopeEmptyMessage('home_page()');
    }); // homepage
    Mousetrap.bind('/ n', function () {
        scopeEmptyMessage('list_new()');
    }); // lista - new
    Mousetrap.bind('/ p', function () {
        scopeEmptyMessage('list_in_progress()');
    }); // lista - in progress
    Mousetrap.bind('/ m p', function () {
        scopeEmptyMessage('list_my_in_progress()');
    }); // lista - my in progress
    Mousetrap.bind('/ r', function () {
        scopeEmptyMessage('list_reopened()');
    }); // lista - reopened
    Mousetrap.bind('/ m r', function () {
        scopeEmptyMessage('list_my_reopened()');
    }); // lista - my reopened
    Mousetrap.bind('/ c', function () {
        scopeEmptyMessage('list_closed()');
    }); // lista - closed
    Mousetrap.bind('/ m c', function () {
        scopeEmptyMessage('list_my_closed()');
    }); // lista - my closed
    Mousetrap.bind('/ a', function () {
        scopeEmptyMessage('list_all()');
    }); // lista - all
    Mousetrap.bind('/ d', function () {
        scopeEmptyMessage('list_deleted()');
    }); // lista - deleted
    Mousetrap.bind('/ s', function () {
        scopeEmptyMessage('list_spam()');
    }); // lista - spam
    Mousetrap.bind('. n', function () {
        scopeEmptyMessage('list_new()');
    }); // lista - new
    Mousetrap.bind('. p', function () {
        scopeEmptyMessage('list_in_progress()');
    }); // lista - in progress
    Mousetrap.bind('. m p', function () {
        scopeEmptyMessage('list_my_in_progress()');
    }); // lista - my in progress
    Mousetrap.bind('. r', function () {
        scopeEmptyMessage('list_reopened()');
    }); // lista - reopened
    Mousetrap.bind('. m r', function () {
        scopeEmptyMessage('list_my_reopened()');
    }); // lista - my reopened
    Mousetrap.bind('. c', function () {
        scopeEmptyMessage('list_closed()');
    }); // lista - closed
    Mousetrap.bind('. m c', function () {
        scopeEmptyMessage('list_my_closed()');
    }); // lista - my closed
    Mousetrap.bind('. a', function () {
        scopeEmptyMessage('list_all()');
    }); // lista - all
    Mousetrap.bind('. d', function () {
        scopeEmptyMessage('list_deleted()');
    }); // lista - deleted
    Mousetrap.bind('. s', function () {
        scopeEmptyMessage('list_spam()');
    }); // lista - spam

    Mousetrap.bind('+', function () {
        scopeEmptyMessage('list_biggerfont()');
    }); // list - bigger font
    Mousetrap.bind('-', function () {
        scopeEmptyMessage('list_smallerfont()');
    }); // list - smaller font
    Mousetrap.bind('0', function () {
        scopeEmptyMessage('list_defaultfont()');
    }); // list - default font
});

/* funkcje pomocnicze */
function list_new() {
    $(location).attr('href', window.register['list_new_url']);
}

function list_in_progress() {
    $(location).attr('href', window.register['list_in_progress_url']);
}

function list_my_in_progress() {
    $(location).attr('href', window.register['list_my_in_progress_url']);
}

function list_reopened() {
    $(location).attr('href', window.register['list_reopened_url']);
}

function list_my_reopened() {
    $(location).attr('href', window.register['list_my_reopened_url']);
}

function list_closed() {
    $(location).attr('href', window.register['list_closed_url']);
}

function list_my_closed() {
    $(location).attr('href', window.register['list_my_closed_url']);
}

function list_all() {
    $(location).attr('href', window.register['list_all_url']);
}

function list_deleted() {
    $(location).attr('href', window.register['list_deleted_url']);
}

function list_spam() {
    $(location).attr('href', window.register['list_spam_url']);
}

function list_biggerfont() {
    $('button[data-action="font_bigger"]').click();
}

function list_smallerfont() {
    $('button[data-action="font_smaller"]').click();
}

function list_defaultfont() {
    $('button[data-action="font_default"]').click();
}


function next_page() {
    // url ustawiany w _paginate.tpl
    if (typeof next_page_url !== 'undefined' && next_page_url != '#') {
        $(location).attr('href', next_page_url);
    }
}

function previous_page() {
    // url ustawiany w _paginate.tpl
    if (typeof previous_page_url !== 'undefined' && previous_page_url != '#') {
        $(location).attr('href', previous_page_url);
    }
}

function create_ticket_page() {
    $(location).attr('href', window.register['create_ticket_url']);
}

function search_page() {
    $(location).attr('href', window.register['search_page_url']);
}

function home_page() {
    $(location).attr('href', window.register['home_page_url']);
}

//przed triggerowaniem shortcutu sprawdzamy czy user nie wypelnil jakiegos pola
function is_message_empty() {
    const body = $('#Body_1_content'),
        note = $('#Note_content'),
        customer_body = $('#Body_0_content');

    if (
        (body.length == 1 && body.val() != '')
        ||
        (note.length == 1 && note.val() != '')
        ||
        (customer_body.length == 1 && customer_body.val() != '')
    ) {
        return false;
    }
    return true;
}

/**
 * sprawdzamy czy uzytkownik wypelnil jakiekolwiek pola
 * jezeli tak - pytamy modalem czy jest pewien
 */
function scopeEmptyMessage(callback) {
    if (is_message_empty()) {
        eval(callback);
        return true;
    }

    var confirmModal = $('<div class="modal fade"><div class="modal-dialog"><div class="modal-content">' +
        '<div class="modal-header">' +
        '<button type="button" class="close" data-dismiss="modal"><span aria-hidden="true"><span class="glyphicon glyphicon-remove"></span></span><span class="sr-only">Close</span></button>' +
        '<h4 class="modal-title">You have attempted to leave this page.</h4>' +
        '</div>' +
        '<div class="modal-body">' +
        '<p>If you have made any changes to the fields without clicking the "Add reply/Note" button, your changes will be lost.<br><br>Click "Ok" to close or click "Cancel" to stay back.</p>' +
        '</div>' +
        '<div class="modal-footer">' +
        '<a href="#" class="btn btn-default" data-dismiss="modal" id="cancelButton">Cancel</a>' +
        '<a href="#" id="modalOkButton" class="btn btn-primary">Ok</a>' +
        '</div>' +
        '</div></div></div>');

    confirmModal.modal('show');

    confirmModal.find('#modalOkButton').bind('click', function (event) {
        event.preventDefault();
        confirmModal.modal('hide');
        eval(callback);
    });
}
